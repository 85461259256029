import { useState } from 'react';
import {
	Typography,
	Grid,
	Checkbox,
	FormControl,
	OutlinedInput,
} from '@mui/material';
import './ExportData.css';
import axios from 'axios';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import { baseURL, reseller } from '../../Constants/APIUrls';

const ExportData = () => {
	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const options = [
		{ id: 1, name: 'XLS' },
		{ id: 2, name: 'CSV' },
	];
	const [fileFormat, setFileFormat] = useState('XLS');
	const [selectedCountry, setSelectedCountry] = useState('All');
	const [readInfo, setReadInfo] = useState(false);

	const handleSelectFileFormat = (event) => {
		setFileFormat(event.target.event);
	};
	const handleReadInfo = () => {
		setReadInfo(!readInfo);
	};
	const handleStartExport = async () => {
		if (fileFormat === 'XLS') {
			try {
				const response = await axios.get(
					`${baseURL}${reseller.xsl.replace(':Country', selectedCountry)}`,
					{
						responseType: 'blob',
					}
				);
				if (response.status === 200) {
					const blob = new Blob([response.data], {
						type: 'application/vnd.ms-excel',
					});
					saveAs(blob, 'Reseller.xls');
				} else {
					console.error('failed to download');
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		} else if (fileFormat === 'CSV') {
			try {
				const response = await axios.get(
					`${baseURL}${reseller.csv.replace(':Country', selectedCountry)}`,
					{
						responseType: 'blob',
					}
				);
				if (response.status === 200) {
					const blob = new Blob([response.data], {
						type: 'text/csv',
					});
					saveAs(blob, 'Reseller.csv');
				} else {
					console.error('failed to download');
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		} else {
			console.log('please select correct file format');
		}
	};
	return (
		<div className="ExportDataWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Export data
			</Typography>
			<hr />
			<Grid container flexDirection={'column'}>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder="Identifying Country"
							value={selectedCountry}
							onChange={(newValue) => setSelectedCountry(newValue)}
						/>
					</Grid>
					<Grid item width="15%">
						<CustomDropDown
							options={options}
							multiple={false}
							label="Select file format"
							id="role-dropdown"
							onChange={handleSelectFileFormat}
							width={'7em'}
							selectedValue={fileFormat}
							defaultValue={'XLS'}
							value={fileFormat}
						/>
					</Grid>
				</Grid>

				<br />
				<Grid item className="textBox">
					<Typography
						sx={{
							fontWeight: 600,
							paddingBottom: '10px',
						}}
					>
						Confidentiality information
					</Typography>
					<FormControl sx={{ width: '100%' }}>
						<OutlinedInput
							value="All information relating to this database are confidential and for internal use only!"
							multiline
							rows={3}
						/>
					</FormControl>
				</Grid>
				<br />
				<br />
				<Grid item className="bottomSection" flexDirection={'row'}>
					<Grid item className="checkBoxText">
						<Checkbox
							{...checkBoxlabel}
							checked={readInfo}
							onClick={handleReadInfo}
						/>

						<Typography>
							I have read and accept the confidentiality information
						</Typography>
					</Grid>
					{readInfo && (
						<Grid item>
							<CustomButton
								text="Start export"
								width="8em"
								handleClick={handleStartExport}
							/>
						</Grid>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default ExportData;
