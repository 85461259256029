import { Backdrop, CircularProgress, Typography } from "@mui/material";

const Loader = ({ open, text }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
    >
      <CircularProgress color="inherit" size="5rem" />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        {text}
      </Typography>
    </Backdrop>
  );
};

export default Loader;
