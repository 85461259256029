import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';

const CustomButton = ({
	handleClick,
	width,
	startIcon,
	height,
	text,
	bgColor,
	color,
	borderColor,
	hoverColor,
	hoverBorder,
	hoverTextColor,
	border,
	endIcon,
	type,
	className,
	margin,
	marginRight,
	marginLeft,
	disabled,

	testId,
}) => {
	const StyledButton = styled(Button)(({ theme }) => ({
		backgroundColor: bgColor || '#E6E6E6',
		width: `${width}`,
		height: `${height}`,
		justifyContent: startIcon ? 'flex-start' : 'center',
		minWidth: 'auto',
		fontSize: '.875rem',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '1.25rem',
		letterSpacing: ' 0.00219rem',
		gap: '0.625rem',
		color: color || '#000',
		textTransform: 'capitalize',
		borderRadius: '.175rem',
		borderColor: borderColor || '',
		border: border || '1px solid',
		margin: margin || '',
		marginRight: marginRight || '',
		marginLeft: marginLeft || '',
		boxShadow: '0 0 0 0',
		'&:hover ': {
			transition: 'background 0.3s',
			backgroundColor: hoverColor || '#fff !important',
			color: hoverTextColor || '#194253',
			border: '1px solid',
			borderColor: hoverBorder || '#194253',
		},
	}));

	return (
		<StyledButton
			variant="contained"
			onClick={handleClick}
			startIcon={startIcon}
			endIcon={endIcon}
			className={className}
			type={type}
			data-testid={testId}
			disabled={disabled}
		>
			{text}
		</StyledButton>
	);
};
CustomButton.propTypes = {
	handleClick: PropTypes.any,
	width: PropTypes.any,
	startIcon: PropTypes.any,
	height: PropTypes.any,
	text: PropTypes.any,
	bgColor: PropTypes.any,
	color: PropTypes.any,
	borderColor: PropTypes.any,
	hoverColor: PropTypes.any,
	hoverBorder: PropTypes.any,
	hoverTextColor: PropTypes.any,
	border: PropTypes.any,
	testId: PropTypes.string,
};

export default CustomButton;
