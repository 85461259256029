import { useState } from 'react';

import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomPagination from './CustomPagination';

const DownloadTable = (props) => {
	const colheaders = [
		{
			key: 'isoCode',
			name: 'Country code',
		},
		{
			key: 'fileName',
			name: 'Document Name',
		},
	];

	return (
		<Grid style={{ marginRight: '3em' }} className="downloadTable">
			<TableContainer component={Paper}>
				<Table>
					<TableHead sx={{ backgroundColor: '#dddddd54' }}>
						<TableRow>
							{colheaders.map((header) => (
								<TableCell
									align="center"
									sx={{
										fontWeight: 'bold',
										borderRight: '1px solid #ccc',
									}}
								>
									{header.name}
								</TableCell>
							))}
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								Download
							</TableCell>
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								Upload
							</TableCell>
							<TableCell
								align="center"
								sx={{
									fontWeight: 'bold',
									borderRight: '1px solid #ccc',
								}}
							>
								Delete
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{props?.tableData?.length === 0 ||
						props?.errorMessage?.length !== 0 ? (
							<TableRow>
								<TableCell></TableCell>
							</TableRow>
						) : (
							props?.tableData?.map((data, index) => (
								<TableRow key={index}>
									{colheaders.map((header) => (
										<TableCell
											align="center"
											sx={{
												borderRight: '1px solid #ccc',
											}}
										>
											{data[fileName]}
										</TableCell>
									))}

									<TableCell align="center">
										<button>Download</button>
									</TableCell>
									<TableCell align="center">
										<button>upload</button>
									</TableCell>
									<TableCell align="center">
										<button>Deleet</button>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<br />

			<Grid className="pagination">
				{props?.tableData?.length !== 0 && (
					<CustomPagination
						total={props?.tableData?.length}
						data={props?.tableData}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default DownloadTable;
