import axios from 'axios';
import { baseURL, reseller, common } from '../Constants/APIUrls';
import { async } from 'q';

export const searchResellerService = async (searchTerm, pageNum, pageSize) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.searchReseller.replace(':Name', searchTerm)}`,
			{
				params: {
					pageNo: pageNum,
					pageSize: pageSize,
				},
			}
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const getAllResellerService = async (market) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.editResellerList}`,
			{
				params: {
					Market: market,
				},
			}
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const geResellerByCountryService = async (selectedCountry) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.resellerByCountryist.replace(':selectedCountry', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getResellersListCsv = async (selectedCountry) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.csv.replace(':Country', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};
export const getResellersListExcel = async (selectedCountry) => {
	try {
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${reseller.xsl.replace(':Country', selectedCountry)}`
		);

		const results = response ? response.data : [];
		return results;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const addReseller = async (formData) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${common.addReseller}`,
			formData
		);
		return response.status === 200;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

export const getResellerById = async (id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.get(
			`${baseURL}${common.getResellerById}`,
			{
				params: {
					Id: id,
				},
			}
		);
		return response.data?.result;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

export const editReseller = async (id) => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});
		const response = await axiosInstance.post(
			`${baseURL}${common.addReseller}`,
			formData
		);
		return response.status === 200;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};
