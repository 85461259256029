import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select, Box, FormControl } from '@mui/material';
import {
	setLanguageDropdown,
	setLanguages,
	setcurrentLanguage,
} from '../../Store/languageSlice';
import i18n from '../../utils/i18n';
import './languageSelector.css';

const LanguageSelector = () => {
	function importAllImages(img) {
		let images = {};
		img.keys().map((item, index) => {
			images[item.replace('./', '')] = img(item);
		});
		return images;
	}
	const images = importAllImages(
		require.context('../../Resources/media', false, /\.(gif|png|svg)$/)
	);
	const languageList = {
		languages: [
			{
				label: 'Bulgarian',
				code: 'bu',
				active: true,
				img: images['bulgaria.gif'],
			},
			{
				label: 'Czech',
				code: 'cz',
				active: true,
				img: images['czechrepublic.gif'],
			},
			{
				label: 'Danish',
				code: 'da',
				active: true,
				img: images['denmark.gif'],
			},
			{
				label: 'Dutch',
				code: 'du',
				active: true,
				img: images['netherlands.gif'],
			},
			{
				label: 'English',
				code: 'en',
				active: true,
				img: images['english.gif'],
			},
			{
				label: 'Estonian',
				code: 'es',
				active: true,
				img: images['estonia.gif'],
			},
			{
				label: 'Finnish',
				code: 'fi',
				active: true,
				img: images['finland.gif'],
			},
			{
				label: 'French',
				code: 'fr',
				active: true,
				img: images['french.gif'],
			},
			{
				label: 'German',
				code: 'de',
				active: true,
				img: images['german.gif'],
			},
			{
				label: 'Greek',
				code: 'gr',
				active: true,
				img: images['greece.gif'],
			},
			{
				label: 'Hungarian',
				code: 'hu',
				active: true,
				img: images['hungary.gif'],
			},
			{
				label: 'Italian',
				code: 'it',
				active: true,
				img: images['italy.gif'],
			},
			{
				label: 'Latvian',
				code: 'la',
				active: true,
				img: images['latvia.gif'],
			},
			{
				label: 'Lithuanian',
				code: 'li',
				active: true,
				img: images['lithuania.gif'],
			},
			{
				label: 'Norwegian',
				code: 'no',
				active: true,
				img: images['norway.gif'],
			},
			{
				label: 'Polish',
				code: 'po',
				active: true,
				img: images['poland.gif'],
			},
			{
				label: 'Portuguese',
				code: 'pr',
				active: true,
				img: images['portugal.gif'],
			},
			{
				label: 'Romanian',
				code: 'ro',
				active: true,
				img: images['romania.gif'],
			},

			{
				label: 'Slovak',
				code: 'sk',
				active: true,
				img: images['slovakia.gif'],
			},
			{
				label: 'Slovenian',
				code: 'sn',
				active: true,
				img: images['slovenia.gif'],
			},
			{
				label: 'Spanish',
				code: 'sp',
				active: true,
				img: images['spain.gif'],
			},
			{
				label: 'Swedish',
				code: 'sw',
				active: true,
				img: images['sweden.gif'],
			},
		],
	};

	const dispatch = useDispatch();

	const languages = useSelector((state) => state?.languages.languages);

	const currentLanguage = useSelector(
		(state) => state?.languages.currentLanguage
	);

	const [selectedLanguage, setSelectedLanguage] = useState({});

	const handleChange = (event) => {
		localStorage.setItem('language', event.target.value);
		i18n.changeLanguage(event.target.value);
		setSelectedLanguage(event.target.value);
		dispatch(setcurrentLanguage(event.target.value));
		dispatch(setLanguageDropdown(true));
	};

	useEffect(() => {
		if (languages) {
			if (
				localStorage.getItem('language') &&
				localStorage.getItem('language') !== 'undefined'
			) {
				const index = languages?.findIndex(
					(lang) => lang.label === localStorage.getItem('language')
				);
				i18n.changeLanguage(languages[index]?.label);
				setSelectedLanguage(languages[index]);
			} else {
				const defaultLanguage = languages?.find((lang) => lang.active === true);
				i18n.changeLanguage(defaultLanguage?.label);
				setSelectedLanguage(defaultLanguage);
				localStorage.setItem('language', defaultLanguage?.label);
				const currentLanguage = defaultLanguage?.label;
				dispatch(setcurrentLanguage(currentLanguage));
				setSelectedLanguage(languages);
				dispatch(setLanguages(languages));
			}
		} else {
			setSelectedLanguage(languages);
			dispatch(setLanguages(languageList.languages));
		}
	}, [languages, dispatch]);
	return (
		<div className="languageSelector" data-testid="country-dropdown">
			<FormControl variant="standard">
				<Select
					MenuProps={{
						PaperProps: {
							className: 'langDropdown',
							sx: {
								backgroundColor: '#fff !important',
								display: 'flex',
								width: '350px',
								padding: '1em',
								flexWrap: 'wrap',
								alignItems: 'center',
								justifyContent: 'center',
								outline: 'unset',
								border: 'unset',
								'&::-webkit-scrollbar': { display: 'none' },

								'& .MuiMenuItem-root.Mui-selected': {
									background: '#ADC8D6',
								},
								'& .MuiMenuItem-root:hover': {
									backgroundColor: '#ADC8D6',
								},
							},
						},
					}}
					className="langBox"
					displayEmpty
					inputProps={{ IconComponent: () => null, 'data-testid': 'my-select' }}
					value={selectedLanguage}
					defaultValue=""
					onChange={handleChange}
					renderValue={() => (
						<Box
							sx={{
								display: 'flex',
								gap: 1,
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<p>{currentLanguage === 'en' ? 'English' : currentLanguage}</p>
						</Box>
					)}
				>
					{languageList.languages?.map((option) => {
						return (
							<MenuItem
								style={{
									// width: "4.35rem",
									fontFamily: 'Roboto',
									fontSize: '0.75rem',
									padding: '0.3rem 0.3rem 0',
									textTransform: 'uppercase',
									display: 'flex',
									flexDirection: 'column',
									width: '24%',
									padding: '1em',
									height: '6em',
									lineHeight: '20px',
									border: '1px solid #ccc',
								}}
								className="langOption"
								key={option.label}
								value={option.label}
							>
								<img src={option.img} />
								<p className="languageText">{option.label}</p>
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};

export default LanguageSelector;
