export const baseURL = process.env.REACT_APP_BASEURL;
console.log(baseURL);

export const reseller = {
	allResellerList: '/reseller/getResellersList',
	xsl: '/reseller/getResellersListExcel?Country=:Country',
	csv: '/reseller/getResellersListCsv?Country=:Country',
	resellerByCountryist:
		'/reseller/getResellersList?selectedCountry=:selectedCountry',
};

export const mpc = {
	search: '/mpc/getMpcByFilte/:selectedCriteria',
	getAllCountries: '/common/getAllCountries',
	getAllCountriesIsoInternational: '/common/getAllCountriesIsoInternational',
};
export const userService = {
	userInfo: '/userInfo',
};
export const common = {
	addReseller: '/common/saveReseller',
	searchReseller: '/common/searchReseller?Name=:Name',
	editResellerList: '/common/getEditResellerList',
	getResellerById: '/common/getResellerById',
	editReseller: '/common/editReseller',
};
