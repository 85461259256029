import './Home.css';
import { useTranslation, Trans } from 'react-i18next';

const Home = () => {
	const { t } = useTranslation();

	return (
		<div className="homeContainer">
			<br />
			<div className="homeContent">
				<p>{t('contextwelcome1')}</p>
				<p>
					<Trans i18nKey="contextwelcome2" />
				</p>
				<p>{t('contextwelcome3')}</p>
				<p>
					{/* {`${t('contextwelcome3_1')} `}
					{`${t('contextwelcome3_2')} ${t('contextwelcome3_3')}`} */}
					{t('contextwelcome3_1')}
					<b>{t('contextwelcome3_2')}</b>
					{t('contextwelcome3_3')}
				</p>

				<p>{t('contextwelcome4')}</p>
				<p>{t('contextwelcome5')}</p>

				<p className="red-text alert-text">{t('add_reseller_warning')}</p>
			</div>
		</div>
	);
};

export default Home;
