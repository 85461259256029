import './EditReseller.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	Grid,
} from '@mui/material';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Loader from '../../CommonComponents/Loader/Loader';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';
import {
	searchResellerService,
	getAllResellerService,
} from '../../Services/resellerServices';
import { roles } from '../../Constants/Permission';

const EditReseller = () => {
	const [tableData, setTableData] = useState([]);
	const { t } = useTranslation();
	const [errorMessage, setErrorMessage] = useState('');
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [resultLoading, setLoading] = useState(false);
	const colheaders = [
		{
			key: 'name',
			name: t('companyName'),
		},
		{
			key: 'alternativename',
			name: t('reseller_label_alternativename'),
		},
		{
			key: 'address',
			name: t('reseller_label_address'),
		},
		{
			key: 'city',
			name: t('reseller_label_city'),
		},
		{
			key: 'identifiyingcountryName',
			name: t('reseller_label_country'),
		},
	];
	const user_info = useSelector((state) => state?.auth?.user);
	const currentRole = useSelector((state) => state?.role?.currentRole);
	const [searchTerm, setSearchTerm] = useState('');

	const handleSearch = () => {
		if (searchTerm === '') {
			setErrorMessage('Please enter 3 characters at least!');
		} else {
			setErrorMessage('');
			setLoading(true);
			searchResellerService(searchTerm)
				.then((data) => {
					if (data.result.length === 0) {
						setLoading(false);
						setErrorMessage(`No results have been found. (${searchTerm})`);
					} else {
						setTableData(data.result);
						setLoading(false);
					}
				})
				.catch(() => {
					setApiError(t('apiError'));
					setLoading(false);
					setOpenError(true);
				});
		}
	};

	useEffect(() => {
		let countries = null;
		if (currentRole === roles.MPC) {
			const countryCode = user_info?.countries.map(
				(country) => country?.countryisocode
			);
			console.log(countryCode);
			countries = countryCode.length ? countryCode.toString() : null;
		}
		setLoading(true);
		getAllResellerService(countries)
			.then((data) => {
				setLoading(false);
				setTableData(data?.result);
			})
			.catch(() => {
				setLoading(false);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, [currentRole]);
	const handlePageChange = async (pageNo) => {
		try {
			setPageNum(pageNo - 1);
			setLoading(true);
			const data = await searchResellerService(
				searchTerm,
				pageNo - 1,
				pageSize
			);
			setLoading(false);
			setTableData(data?.result);
		} catch (err) {
			console.error(err);
			setLoading(false);
			setErrorMessage('Something went wrong, Please try again!');
		}
	};
	return (
		<div className="EditResellerWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_6')}
			</Typography>
			<hr />
			<Grid container>
				<div className="searchInputWrapper">
					<div className="searchInput">
						<TextField
							variant="outlined"
							placeholder="Please enter 3 character to search"
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							sx={{
								'& .MuiOutlinedInput-input': {
									height: '100%',
									padding: '10px',
								},
								'& input::placeholder': {
									fontSize: '14px',
								},
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={handleSearch}
											edge="end"
											sx={{ width: '34px' }}
										>
											<Search />
										</IconButton>
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					</div>
					<div>
						<p className="error-text">{errorMessage}</p>
					</div>
				</div>
				<br />
				<Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
					<CustomTable
						page="reseller/edit"
						tableData={tableData?.reseller}
						errorMessage={errorMessage}
						colheaders={colheaders}
						width={colheaders.length * 12}
					/>
				</Grid>
				<Grid item>
					<div className="pagination">
						{tableData?.length !== 0 && (
							<CustomPagination
								total={tableData.length}
								totalRecords={tableData?.totalNumberOfRecord}
								handlePageChange={handlePageChange}
								pageSize={pageSize}
								pageNo={pageNum}
								totalPages={tableData?.numberOfPages}
							/>
						)}
					</div>
				</Grid>
			</Grid>
			<p className="error-text">{t('footerresults')}</p>
			<br />
			<br />
			<Loader open={resultLoading} text={'Loading'} />
		</div>
	);
};

export default EditReseller;
