import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
	Typography,
	Card,
	Grid,
	Checkbox,
	FormControlLabel,
	Paper,
	Button,
	FormControl,
	OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilePreviewCard from '../../CommonComponents/Cards/FilePreviewCard';
import './AddNewReseller.css';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import { getCountryListService } from '../../Services/getCountryListService';
import { addReseller } from '../../Services/resellerServices';
import SuccessToast from '../../CommonComponents/Toast/SuccessToast';
import ErrorToast from '../../CommonComponents/Toast/ErrorToast';
import { roles } from '../../Constants/Permission';
import Loader from '../../CommonComponents/Loader/Loader';

const AddNewReseller = () => {
	const { t } = useTranslation();
	const currentRole = useSelector((state) => state?.role?.currentRole);
	const user_info = useSelector((state) => state?.auth?.user);

	const [resultLoading, setResultLoading] = useState(false);
	const [fileValue, setFileValue] = useState([]);
	const [uploadFileError, setUploadFileError] = useState(null);
	const [previewUrl, setPreviewUrl] = useState('');
	const [dragOver, setDragOver] = useState(false);
	const [selectedAllCountry, setSelectedAllCountry] = useState('');
	const [identifiyingcountry, setidentifiyingcountry] = useState('');
	const [salesActivitycountryList, setSalesActivitycountryList] = useState([]);
	const [selectedSalesActivity, setSelectedSalesActivity] = useState([]);
	const [division, setDivision] = useState([]);
	const [date, setDate] = useState(null);
	const [openToast, setOpenToast] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [apiError, setApiError] = useState(null);

	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };

	useEffect(() => {
		getCountryListService()
			.then((countries) => {
				setSalesActivitycountryList(countries?.result);
			})
			.catch((err) => {
				console.error(err);
				setApiError(t('apiError'));
				setOpenError(true);
			});
	}, []);
	const handleCloseError = (reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenError(false);
		setApiError(null);
	};
	const handleDragEnter = (e) => {
		e.preventDefault();
		setDragOver(true);
	};
	const initialValues = {
		name: '',
		alternativename: '',
		commercialregistrationnumber: '',
		address: '',
		housenumber: '',
		zipcode: '',
		city: '',
		country: '',
		telephonenumber: '',
		faxnumber: '',
		homepage: '',
		identifiyingcountry: '',
		changetimestamp: null,
		countries: [],
		remarks: '',
		status: currentRole === roles.HQ || currentRole === roles.MPC ? '1' : '0',
		reportingRetailerEmail: null,
		reportingLanguage: null,
		divisionalfocus: '',
		classificationverifiedbyHq:
			currentRole === roles.HQ ? user_info?.userName : null,
		classificationverifiedbyMpc:
			currentRole === roles.MPC ? user_info?.userName : null,
		reportingRetailer:
			currentRole === roles.RETAILER ? user_info?.userName : null,
	};
	const validationSchema = Yup.object({
		// name: Yup.string().required('Company name is required'),
		// address: Yup.string().required('Address  is required'),
		// housenumber: Yup.string().required('House Number is required'),
		// zipcode: Yup.string().required('Zip code is required'),
		// city: Yup.string().required('City is required'),
	});
	const handleDragLeave = () => {
		setDragOver(false);
	};
	const supportedMediaType = ['pdf'];
	const maxFiles = 5;

	const handleDrop = async (e) => {
		e.preventDefault();
		const files = e.dataTransfer.files;
		console.log(files);
		if (files?.length + fileValue?.length > maxFiles) {
			setUploadFileError('Maximum file count is 5');
		} else {
			setUploadFileError(null);
			setFileValue((prevState) => [...prevState, ...files]);
		}
	};
	const uploadFiles = async (e) => {
		e.preventDefault();
		const files = e.target.files;
		if (files?.length + fileValue?.length > maxFiles) {
			setUploadFileError(`${t('maxFileError')} : ${maxFiles}`);
		} else {
			setUploadFileError(null);
			setFileValue((prevState) => [...prevState, ...files]);
		}
	};
	const uploadContainerStyle = {
		flexDirection: 'column',
		alignItems: 'center',
		border: '2px dashed #E5E5E5',
		width: '93%',
		margin: '0 auto',
		height: '18rem',
		display: 'flex',
		marginTop: '2rem',
	};
	//Onclick delete file uploaded
	const handleDelete = async (fileKey) => {
		const imgArr = fileValue;
		// removing selected file from the file preview cards
		imgArr.splice(fileKey.fileKey, 1);
		setFileValue(imgArr);
		//Updating the image url for the file preview cards
		const imageUrlArr = imgArr.map((file) => URL.createObjectURL(file));
		const objectUrlArray = imageUrlArr.reduce((acc, value) => {
			return [...acc, value];
		}, []);
		setPreviewUrl(objectUrlArray);
		setPreviewUrl(objectUrlArray);
	};
	const fileTypeValidation = async () => {
		try {
			let success = true;
			for await (const file of fileValue) {
				const parts = file?.name?.split('.');
				const extension = parts.pop();
				if (extension.toUpperCase() !== 'PDF') {
					success = true;
					break;
				}
			}
			return success;
		} catch (err) {
			return false;
		}
	};
	const submitForm = async (values) => {
		try {
			let fileValidationResult;
			if (fileValue.length === 0) {
				setUploadFileError(t('minFileError'));
			} else {
				fileValidationResult = await fileTypeValidation();
			}
			values.country = selectedAllCountry;
			values.identifiyingcountry = identifiyingcountry;
			values.countries = selectedSalesActivity;
			values.changetimestamp = date;
			const divisionArray = division.sort((a, b) => b.localeCompare(a));
			values.divisionalfocus = divisionArray.length ? division.join('||') : '';

			if (
				!uploadFileError &&
				fileValue.length !== 0 &&
				fileValidationResult === true
			) {
				const formData = new FormData();

				for (let i = 0; i < fileValue.length; i++) {
					formData.append('file', fileValue[i]);
				}
				formData.append('data', JSON.stringify(values));
				setResultLoading(true);
				const result = await addReseller(formData);
				if (result) {
					setOpenToast(true);
					setFileValue([]);
				}
				setResultLoading(false);
			}
		} catch (err) {
			console.error(err);
			setResultLoading(false);
			setApiError(t('apiError'));
			setOpenError(true);
		}
	};
	return (
		<Formik
			onSubmit={submitForm}
			initialValues={initialValues}
			validationSchema={validationSchema}
		>
			{(formik) => {
				const { handleChange, handleSubmit, setFieldError, errors } = formik;
				return (
					<form onSubmit={handleSubmit}>
						<div className="addNewResellerWrapper">
							<Typography
								noWrap
								sx={{
									color: '#151C2D',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 600,
									fontFamily: 'Arial, Helvetica, sans-serif',
								}}
							>
								{t('navlevel1_5')}
							</Typography>
							<hr />
							<div className="addNewResellerContent">
								<Card
									sx={{
										padding: '2em 1.5em',
									}}
								>
									<Grid container width="100%" flexDirection={'column'}>
										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_company_panel')}
										</Typography>
										<Grid item className="companyDetails">
											<Grid item>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('companyName')}
													</Typography>
													<CustomTextField
														placeholder={t('companyName')}
														width="90%"
														height="2.5em"
														name="name"
														// error={!!errors.name}
														handleChange={handleChange}
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles">
														{t('reseller_label_alternativename')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_alternativename')}
														width="90%"
														height="2.5em"
														name="alternativename"
														handleChange={handleChange}
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles">
														{t('reseller_label_comregnumber')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_comregnumber')}
														width="90%"
														height="2.5em"
														name="commercialregistrationnumber"
														handleChange={handleChange}
													/>
												</div>
											</Grid>
											<br />
											<div style={{ display: 'flex', flexDirection: 'row' }}>
												<Typography className="required">
													{t('reseller_label_publicCompany')}
												</Typography>
												<Checkbox
													{...checkBoxlabel}
													defaultChecked
													style={{ height: '2rem' }}
												/>
											</div>
										</Grid>
										<br />
										<br />

										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_address_panel')}
										</Typography>
										<Grid item className="address">
											<Grid item>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_address')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_address')}
														width="90%"
														height="2.5em"
														name="address"
														handleChange={handleChange}
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_housenumber')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_housenumber')}
														width="90%"
														height="2.5em"
														name="housenumber"
														handleChange={handleChange}
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_zipcode')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_zipcode')}
														width="90%"
														height="2.5em"
														name="zipcode"
														handleChange={handleChange}
													/>
												</div>
											</Grid>
											<br />
											<Grid item>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_city')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_city')}
														width="90%"
														height="2.5em"
														name="city"
														handleChange={handleChange}
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_country')}
													</Typography>
													<AutoCompleteCountryDropdown
														width="90%"
														height="2.5em"
														placeholder={t('reseller_label_country')}
														value={selectedAllCountry}
														onChange={(newValue) =>
															setSelectedAllCountry(newValue)
														}
														country="All"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles">
														{t('reseller_label_phonenumber')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_phonenumber')}
														width="90%"
														height="2.5em"
														name="telephonenumber"
														handleChange={handleChange}
													/>
												</div>
											</Grid>
											<br />
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													width: '33%',
												}}
											>
												<Typography className="fieldTitles">
													{t('reseller_label_faxnumber')}
												</Typography>
												<CustomTextField
													placeholder={t('reseller_label_faxnumber')}
													width="90%"
													height="2.5em"
													name="faxnumber"
													handleChange={handleChange}
												/>
											</div>
										</Grid>
										<br />
										<br />
										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_furtherinformation_panel')}
										</Typography>
										<Grid item className="futherInformation">
											<Grid item>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles">
														{t('reseller_label_homepage')}
													</Typography>
													<CustomTextField
														placeholder={t('reseller_label_homepage')}
														width="90%"
														height="2.5em"
														handleChange={handleChange}
														name="homepage"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_identifyingcountry')}
													</Typography>
													<AutoCompleteCountryDropdown
														width="90%"
														height="2.5em"
														placeholder={t('reseller_label_identifyingcountry')}
														value={identifiyingcountry}
														onChange={(newValue) =>
															setidentifiyingcountry(newValue)
														}
														country="All"
													/>
												</div>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														width: '33%',
													}}
												>
													<Typography className="fieldTitles required">
														{t('reseller_label_dateofverificationbympc')}
													</Typography>
													<LocalizationProvider dateAdapter={AdapterDayjs}>
														<DemoContainer components={['DatePicker']}>
															<DatePicker
																slotProps={{
																	textField: {
																		size: 'small',
																		placeholder: t(
																			'reseller_label_dateofverificationbympc'
																		),
																	},
																}}
																onChange={(value) => {
																	setDate(value.format('YYYY-MM-DD'));
																}}
															/>
														</DemoContainer>
													</LocalizationProvider>
												</div>
											</Grid>
											<br />
											<Grid
												item
												width="30%"
												alignItems={'center'}
												className="division"
											>
												<Typography className="required">
													{t('reseller_label_divisionalfocus')}
												</Typography>
												&nbsp;&nbsp;
												<FormControlLabel
													width="25%"
													control={<Checkbox name="bus" />}
													label={t('maintain_mpc_division_bus')}
													onChange={(e) => {
														if (e.target.checked) {
															setDivision([...division, 'B']);
														} else {
															const filteredDivision = division.filter(
																(div) => div !== 'B'
															);
															setDivision(filteredDivision);
														}
													}}
												/>
												<FormControlLabel
													width="25%"
													control={<Checkbox name="truck" />}
													label={t('maintain_mpc_division_truck')}
													onChange={(e) => {
														if (e.target.checked) {
															setDivision([...division, 'T']);
														} else {
															const filteredDivision = division.filter(
																(div) => div !== 'T'
															);
															setDivision(filteredDivision);
														}
													}}
												/>
											</Grid>
										</Grid>
										<br />
										<br />
										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_knownsalesactivityEU_panel')}
										</Typography>
										<Grid item className="knowActivities" width="100%">
											<Grid item className="countryCheckBoxWrapper">
												{salesActivitycountryList?.map((country) => {
													return (
														<Grid item className="countries" width="25%">
															<FormControlLabel
																control={
																	<Checkbox name={country.countryisocode} />
																}
																label={
																	<Typography style={{ fontSize: '14px' }}>
																		{country.countryName}
																	</Typography>
																}
																onChange={(e) => {
																	if (e.target.checked) {
																		setSelectedSalesActivity([
																			...selectedSalesActivity,
																			country,
																		]);
																	} else {
																		const filteredSalesActivity =
																			selectedSalesActivity.filter(
																				(data) =>
																					data?.countryisocode !==
																					country.countryisocode
																			);
																		setSelectedSalesActivity(
																			filteredSalesActivity
																		);
																	}
																}}
															/>
														</Grid>
													);
												})}
											</Grid>
										</Grid>
										<br />
										<br />
										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_remarks')}
										</Typography>
										<Typography className="fieldTitles">
											{t('reseller_label_remarksnotes')}
										</Typography>
										<Grid item className="dataInput" width="100%">
											<FormControl sx={{ width: '100%' }}>
												<OutlinedInput
													placeholder="Don't add personal and confidential data"
													multiline
													rows={5}
													name="remarks"
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
										<br />
										<br />
										<Typography
											sx={{
												fontWeight: 600,
												paddingBottom: '10px',
											}}
										>
											{t('reseller_label_evidences_panel')}
										</Typography>
										<Grid item className="documents" width="100%">
											<Paper
												className="fileUpload"
												style={{
													...uploadContainerStyle,
													...(dragOver ? { border: '2px dashed blue' } : {}),
													paddingTop: '3rem',
													paddingBottom: '1em',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													alignItems: 'center',
												}}
												onDragEnter={handleDragEnter}
												onDragOver={handleDragEnter}
												onDragLeave={handleDragLeave}
												onDrop={handleDrop}
												onChange={uploadFiles}
											>
												<label htmlFor="fileInput">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="100"
														height="70"
														viewBox="0 0 146 122"
														fill="none"
													>
														<path
															d="M73 62.0176V119"
															stroke="#C8C8C8"
															strokeWidth="6"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M125.798 100.684C131.932 97.3087 136.778 91.9673 139.571 85.5031C142.364 79.039 142.945 71.8201 141.221 64.9859C139.498 58.1518 135.569 52.0915 130.054 47.7616C124.539 43.4316 117.752 41.0787 110.765 41.074H102.84C100.937 33.6417 97.3882 26.7417 92.4621 20.8928C87.5359 15.0439 81.3601 10.3982 74.399 7.30508C67.4379 4.21193 59.8727 2.75179 52.272 3.03444C44.6713 3.31709 37.233 5.33516 30.5163 8.93695C23.7996 12.5387 17.9793 17.6305 13.493 23.8295C9.00659 30.0284 5.97092 37.1732 4.61417 44.7268C3.25742 52.2803 3.61489 60.046 5.65972 67.44C7.70455 74.834 11.3835 81.664 16.42 87.4163"
															stroke="#C8C8C8"
															strokeWidth="6"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M99.4562 86.4386L73.0001 62.0176L46.5439 86.4386"
															stroke="#C8C8C8"
															strokeWidth="6"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>

													<Typography
														sx={{
															width: 'auto',
															height: '2rem',
															textAlign: 'center',
															fontSize: '0.875rem',
															fontStyle: 'normal',
															fontWeight: 400,
															lineHeight: '2.5rem',
															color: '#000',
														}}
													>
														{t('dragAndDropText')}
													</Typography>

													<Typography
														sx={{
															width: '11.25rem',
															height: '2rem',
															textAlign: 'center',
															fontSize: '0.875rem',
															fontStyle: 'normal',
															fontWeight: 400,
															lineHeight: '2.5rem',
															color: '#000',
														}}
													>
														{t('or')}
													</Typography>
												</label>
												<br />
												<Button
													component="label"
													sx={{
														background: '#E6E6E6',
														color: '#000',
														display: 'flex',
														width: '14.5rem',
														height: '2.5rem',
														justifyContent: 'center',
														alignItems: 'center',
														flexShrink: 0,
														borderRadius: '.175rem',
														border: '1px solid',
														textTransform: 'none',
														'&:hover ': {
															transition: 'background 0.3s',
															backgroundColor: '#fff !important',
															color: '#194253',

															border: '1px solid',
														},
													}}
												>
													{t('selectFiles')}
													<input
														type="file"
														accept="application/pdf"
														hidden
														id="fileInput"
														multiple
													/>
												</Button>
												<div
													style={{ display: 'flex', justifyContent: 'center' }}
												>
													<p style={{ color: '#bcbcbc' }}>
														{`${t('maxFileError')} : ${maxFiles}`}
													</p>
												</div>
											</Paper>
											<div
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												<p className="red-text">{uploadFileError} </p>
											</div>

											<Grid
												container
												width="93%"
												style={{
													margin: '1rem auto 0',
													justifyContent: 'space-between',
												}}
											>
												{Object.keys(fileValue)?.map((file, index) => (
													<Grid
														item
														key={fileValue[file].name}
														style={{ width: '49%', marginBottom: '1rem' }}
													>
														<FilePreviewCard
															fileKey={file}
															fileName={fileValue[file].name}
															fileSize={fileValue[file].size}
															onDeleteFile={handleDelete}
															previewimage={previewUrl[index]}
															width="auto"
														/>
													</Grid>
												))}
											</Grid>
										</Grid>
										<br />
										<Grid
											item
											className="btn"
											width="100%"
											display={'flex'}
											justifyContent={'flex-end'}
											marginTop="2em"
										>
											<CustomButton text="cancel" width="10em" />
											<CustomButton
												text="save"
												handleClick={handleSubmit}
												width="10em"
												marginLeft="2em"
											/>

											<br />
											<br />
										</Grid>
									</Grid>
								</Card>
							</div>
							<br />
							<p
								className="red-text"
								style={{ marginBottom: '5em', fontSize: '14px' }}
							>
								{t('add_reseller_warning')}
							</p>
						</div>
						<ErrorToast
							open={openError}
							handleClose={handleCloseError}
							message={apiError}
						/>
						<SuccessToast
							open={openToast}
							message={t('requestSuccess')}
							handleClose={(event, reason) => {
								if (reason === 'clickaway') {
									return;
								}
								setOpenToast(false);
							}}
						/>
						<Loader open={resultLoading} text={'Loading'} />
					</form>
				);
			}}
		</Formik>
	);
};

export default AddNewReseller;
