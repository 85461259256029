import { useState } from 'react';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import './SearchReseller.css';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import { searchResellerService } from '../../Services/resellerServices';
import CustomPagination from '../../CommonComponents/Table/CustomPagination';

const searchReseller = () => {
	const { t } = useTranslation();
	const [searchTerm, setSearchTerm] = useState('');
	const [tableData, setTableData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [pageNum, setPageNum] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const colheaders = [
		{
			key: 'name',
			name: t('companyName'),
		},
		{
			key: 'address',
			name: t('reseller_label_address'),
		},
		{
			key: 'alternativename',
			name: t('reseller_label_alternativename'),
		},
		{
			key: 'identifiyingcountry',
			name: t('reseller_label_identifyingcountry'),
		},
		{
			key: 'remarks',
			name: t('remarks'),
		},
		{
			key: 'divisionalfocus',
			name: t('reseller_label_divisionalfocus'),
		},
	];

	const handleSearch = () => {
		if (searchTerm.length < 3) {
			setErrorMessage('Please enter 3 characters at least!');
		} else {
			setErrorMessage('');

			searchResellerService(searchTerm, pageNum, pageSize)
				.then((data) => {
					if (data?.result?.reseller?.length === 0) {
						setErrorMessage(`${t('SYSTEM_ERROR_Search3')} (${searchTerm})`);
					} else {
						setTableData(data?.result);
					}
				})
				.catch(() => {
					setErrorMessage('Something went wrong, Please try again!');
				});
		}
	};
	const handlePageChange = async (pageNo) => {
		try {
			setPageNum(pageNo - 1);
			const data = await searchResellerService(
				searchTerm,
				pageNo - 1,
				pageSize
			);
			setTableData(data?.result);
		} catch (err) {
			console.error(err);
			setErrorMessage('Something went wrong, Please try again!');
		}
	};
	return (
		<div className="searchWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '1rem',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				{t('navlevel1_1')}
			</Typography>
			<hr />
			<div className="searchInputWrapper">
				<div className="searchInput">
					<TextField
						variant="outlined"
						placeholder={t('searchparInvalid')}
						value={searchTerm}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
						sx={{
							'& .MuiOutlinedInput-input': {
								height: '100%',
								padding: '10px',
							},
							'& input::placeholder': {
								fontSize: '14px',
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={handleSearch}
										edge="end"
										sx={{ width: '34px' }}
									>
										<Search />
									</IconButton>
								</InputAdornment>
							),
						}}
						fullWidth
					/>
				</div>
				<div>
					<p className="error-text">{errorMessage}</p>
				</div>
			</div>

			<div className="searchTable">
				<CustomTable
					tableData={tableData?.reseller}
					errorMessage={errorMessage}
					colheaders={colheaders}
				/>
			</div>
			<br />
			<div className="pagination">
				{tableData?.length !== 0 && (
					<CustomPagination
						total={tableData.length}
						totalRecords={tableData?.totalNumberOfRecord}
						handlePageChange={handlePageChange}
						pageSize={pageSize}
						pageNo={pageNum}
						totalPages={tableData?.numberOfPages}
					/>
				)}
			</div>
			<p className="error-text">{t('footerresults')}</p>
			<br />
			<br />
		</div>
	);
};

export default searchReseller;
