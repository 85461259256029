import { Snackbar, Alert as MuiAlert } from "@mui/material";
import PropTypes from "prop-types";

const ErrorToast = ({ open, handleClose, message }) => {
  try {
    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="error"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    );
  } catch (err) {
    console.error(err);
  }
};
ErrorToast.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  message: PropTypes.any,
};

export default ErrorToast;
