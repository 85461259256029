import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import {
	getCountryListService,
	getAllCountryListService,
} from '../../Services/getCountryListService';

const AutoCompleteCountryDropdown = (props) => {
	const [countryList, setCountryList] = useState([]);

	const styles = {
		textField: {
			'& .MuiInputBase-root': {
				color: '#000',
				background: '#fff',
				flexShrink: 0,
				borderRadius: '.175rem',
				border: ' 1px #E5E5E5',
				padding: '0 1rem',
				width: `${props.width}`,
				height: `${props.height}`,
				//maxHeight: `2.5rem`,
			},
			'& input::placeholder': {
				fontSize: '14px',
			},

			'& .Mui-focused': {
				maxHeight: `unset`,
			},
		},
	};
	useEffect(() => {
		if (props?.country === 'All') {
			getAllCountryListService()
				.then((country) => {
					setCountryList(country?.result);
				})
				.catch((err) => {
					console.error(err);
					setCountryList([]);
				});
		} else if (props?.country === 'European') {
			getCountryListService()
				.then((country) => {
					setCountryList(country?.result);
				})
				.catch((err) => {
					console.error(err);
					setCountryList([]);
				});
		}
	}, []);
	let defaultValue;
	if (props?.defaultValue) {
		defaultValue = countryList.find(
			(country) => country.countryisocode === props?.defaultValue
		);
	}
	return (
		<Autocomplete
			id="autocomplete"
			noOptionsText={countryList?.length && 'no Matches Found'}
			options={countryList?.map((countryList) => countryList?.countryName)}
			sx={{ width: '100%' }}
			onChange={(event, newValue) => {
				props?.onChange(newValue);
			}}
			value={props?.value}
			renderInput={(params) => (
				<TextField
					sx={styles.textField}
					{...params}
					placeholder={props?.placeholder}
					value={props?.value}
				/>
			)}
		/>
	);
};

AutoCompleteCountryDropdown.propTypes = {
	id: PropTypes.any,
	key: PropTypes.any,
	name: PropTypes.any,
	handleChange: PropTypes.any,
	placeholder: PropTypes.any,
};
export default AutoCompleteCountryDropdown;
