import { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTextField from '../../CommonComponents/TextField/CustomTextField';

import { Search } from 'react-feather';
import './MaintainContact.css';

const MaintainContact = () => {
	const [selectedCountry, setSelectedCountry] = useState('All');
	const [fileFormat, setFileFormat] = useState('XLS');
	const handleSelectFileFormat = (event) => {
		setFileFormat(event.target.event);
	};
	return (
		<div className="maintainContactWrapper">
			<Typography
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Maintain Contact
			</Typography>
			<hr />
			<br />
			<Grid container direction={'row'} gap={2}>
				{/* search bar */}
				<Grid item>
					<CustomTextField
						placeholder={'Please enter at least 3 characters'}
						width="18rem"
						height="2rem"
					/>
				</Grid>
				<CustomButton width={'3rem'} height={'2rem'} startIcon={<Search />} />

				{/* Country selector */}
				<Grid item>
					<Grid
						container
						direction="row"
						columnGap={2}
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Typography>{`Select Country`}</Typography>
						</Grid>
						<Grid item>
							<AutoCompleteCountryDropdown
								width="18em"
								height="2em"
								placeholder="Identifying country"
								value={selectedCountry}
								onChange={(newValue) => setSelectedCountry(newValue)}
								country="All"
							/>
						</Grid>
					</Grid>
				</Grid>
				{/* File selector */}
				<Grid item>
					<Grid
						container
						direction="row"
						columnGap={2}
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<Typography>{`Select File Format`}</Typography>
						</Grid>
						<Grid item>
							<CustomDropDown
								options={[
									{ id: 1, name: 'XLS' },
									{ id: 2, name: 'CSV' },
								]}
								multiple={false}
								label="Select file format"
								id="role-dropdown"
								onChange={handleSelectFileFormat}
								width="7em"
								height="2em"
								value={fileFormat}
								defaultValue={'XLS'}
							/>
						</Grid>
						<Grid item>
							<CustomButton text="Start Export" height={'2rem'} width="8rem" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default MaintainContact;
