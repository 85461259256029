import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
		isAuthenticated: false,
		displayPicture: null,
	},
	reducers: {
		login: (state, action) => {
			state.user = action.payload;
			state.isAuthenticated = true;
		},
		logout: (state) => {
			state.user = null;
			state.isAuthenticated = false;
		},
		setDisplayPicture: (state, action) => {
			state.displayPicture = action.payload;
		},
	},
});

export const { login, logout, setDisplayPicture } = authSlice.actions;
export default authSlice.reducer;
