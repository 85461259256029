import { useState } from 'react';
import axios from 'axios';
import { baseURL, reseller } from '../../Constants/APIUrls';
import { Typography, Grid, Checkbox } from '@mui/material';
import './DataReview.css';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';

const DataReview = () => {
	const checkBoxlabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const [selectedCountry, setSelectedCountry] = useState('All');

	const handleStartExport = async () => {
		try {
			const response = await axios.get(
				`${baseURL}${reseller.xsl.replace(':Country', selectedCountry)}`,
				{
					responseType: 'blob',
				}
			);
			if (response.status === 200) {
				const blob = new Blob([response.data], {
					type: 'application/vnd.ms-excel',
				});
				saveAs(blob, 'Reseller.xls');
			} else {
				console.error('failed to download');
			}
		} catch (err) {
			console.error(err);
			throw new Error(err);
		}
	};
	return (
		<div className="DataReviewWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Data review
			</Typography>
			<hr />
			<Grid container flexDirection={'column'}>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="100%">
						<p className="dataReviewText">
							Please check your reseller data. Follow this process for your
							review:
						</p>
					</Grid>
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder="Identifying Country"
							value={selectedCountry}
							onChange={(newValue) => setSelectedCountry(newValue)}
						/>
					</Grid>
				</Grid>
				<Grid item className="TextContainer">
					<p className="dataReviewText">
						1. Download your market relevant data set here:
					</p>
					<CustomButton
						text="Start export"
						width="8em"
						height="30px"
						marginLeft="15px"
						handleClick={handleStartExport}
					/>

					<br />
					<br />
					<p className="dataReviewText">
						2. Check the information of all listed resellers
					</p>
					<p className="dataReviewText">
						3. If necessary make any changes by using the functionality "Edit
						reseller data"
					</p>
					<p className="dataReviewText">4. Last, confirm your verification:</p>

					<Grid item className="checkBoxBlock">
						<div className="checkBoxBtn">
							<Checkbox {...checkBoxlabel} defaultChecked />
						</div>
						<p className="dataReviewText">
							TRUCK : I herewith confirm that I have checked and where necessary
							updated or deleted all reseller data and in addition all documents
							in the download area for my market. Furthermore I checked that due
							to General Data Protection Regulation (GDPR) no companies
							constituted under civil laws and registered sole traders are on
							the database.
						</p>

						<br />
						<div className="checkBoxBtn">
							<Checkbox {...checkBoxlabel} defaultChecked />
						</div>

						<p className="dataReviewText">
							BUS : I herewith confirm that I have checked and where necessary
							updated or deleted all reseller data and in addition all documents
							in the download area for my market. Furthermore I checked that due
							to General Data Protection Regulation (GDPR) no companies
							constituted under civil laws and registered sole traders are on
							the database
						</p>
					</Grid>
				</Grid>
				<br />
				<CustomButton text="Confirm" width="8em" />
			</Grid>
		</div>
	);
};

export default DataReview;
