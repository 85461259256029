import { Snackbar, Alert as MuiAlert } from "@mui/material";
import PropTypes from "prop-types";

const SuccessToast = ({ open, handleClose, message }) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="success"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
SuccessToast.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  message: PropTypes.any,
};
export default SuccessToast;
