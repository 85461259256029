import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import PropTypes from 'prop-types';

const CustomDropDown = (props) => {
	const [selectedFields, setSelectedFields] = useState([]);
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: props.height ? props.height : 48 * 4.5 + 8,
			},
			sx: {
				backgroundColor: '#fff',
				'& .MuiMenuItem-root.Mui-selected': {
					backgroundColor: '#fff',
				},
				'& .MuiMenuItem-root:hover': {
					backgroundColor: '#ADC8D6',
				},
			},
		},
	};
	const handleChange = (event) => {
		setSelectedFields(event.target.value);
		props.onChange(event.target.value);
	};
	return (
		<div data-testid={props.testid}>
			<FormControl>
				<Select
					labelId={props.labelId}
					id={props.id}
					multiple={props.multiple}
					value={props.value || []}
					defaultValue=""
					onChange={handleChange}
					input={<OutlinedInput sx={{ color: '#ffffff' }} />}
					renderValue={(selected) => {
						if (props.multiple) {
							return selected.join(', ');
						} else {
							return selected;
						}
					}}
					sx={{
						background: '#fff',
						display: 'flex',
						width: props.width,
						height: props?.height || '2.5rem',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '0.375rem',
						border: '1px solid',
						borderColor: '#E5E5E5',
					}}
					MenuProps={MenuProps}
				>
					{props?.options?.map((option) => {
						return (
							<MenuItem
								key={option.id}
								value={option.name}
								data-testid={`test-${option.name}`}
								sx={{
									background: '#fff',
								}}
							>
								{props.multiple && (
									<Checkbox
										style={{ color: '#34778F' }}
										checked={selectedFields?.includes(option.name)}
									/>
								)}
								<ListItemText primary={option.name} sx={{ color: '#000' }} />
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};

CustomDropDown.propTypes = {
	id: PropTypes.any,
	onChange: PropTypes.any,
	height: PropTypes.any,
	width: PropTypes.any,
	options: PropTypes.any,
	testid: PropTypes.any,
	value: PropTypes.any,
	labelId: PropTypes.any,
	multiple: PropTypes.any,
};
export default CustomDropDown;
