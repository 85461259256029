import { Typography, Grid } from '@mui/material';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import DownloadSection from './DownloadSection';
import './Download.css';

const Download = () => {
	const location = useLocation();
	const isSectionSelected = location.pathname.includes('/download/');

	const downloadOptions = [
		{
			label: 'Download Reseller Guidelines',
			path: 'download-reseller-guidelines',
		},
		{ label: 'Sample letters for declaration of use', path: 'sample-letters' },
		{ label: 'Declaration of use fleet customer', path: 'fleet-customer' },
		{
			label: 'Declaration of use independent workshops',
			path: 'independent-workshops',
		},
		{ label: 'Declaration of use Body builders', path: 'body-builders' },
		{ label: 'New Reseller Template', path: 'reseller-template' },
		{ label: 'FAQ', path: 'faq' },
	];

	return (
		<div className="DownloadWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Download
			</Typography>
			<hr />
			{!isSectionSelected && (
				<Grid container flexDirection={'column'} className="listOfDoc">
					{downloadOptions.map((option) => (
						<Grid item key={option.path}>
							<Typography
								noWrap
								sx={{
									color: '#151C2D',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 600,
									fontFamily: 'Arial, Helvetica, sans-serif',
								}}
							>
								<Link className="downloadLink" to={`/download/${option.path}`}>
									{option.label}
								</Link>
							</Typography>
						</Grid>
					))}
				</Grid>
			)}

			<Routes>
				<Route path=":section" element={<DownloadSection />} />
			</Routes>
		</div>
	);
};

export default Download;
