import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import CustomButton from '../../CommonComponents/Button/CustomButton';
import CustomTable from '../../CommonComponents/Table/CustomTable';
import AutoCompleteCountryDropdown from '../../CommonComponents/Dropdown/AutoCompleteCountryDropdown';
import './VerificationOfReseller.css';
import CustomDropDown from '../../CommonComponents/Dropdown/CustomDropDown';
import { useState, useEffect } from 'react';
import { getAllResellerService } from '../../Services/resellerServices';
import { baseURL, reseller } from '../../Constants/APIUrls';

const VerificationOfReseller = () => {
	const options = [
		{ id: 1, name: 'XLS' },
		{ id: 2, name: 'CSV' },
	];

	const [fileFormat, setFileFormat] = useState('XLS');
	const [tableData, setTableData] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('All');
	const colheaders = [
		{
			key: 'name',
			name: 'Company Name',
		},
		{
			key: 'address',
			name: 'Address',
		},
		{
			key: 'alternativename',
			name: 'Alternative name',
		},
		{
			key: 'identifiyingcountry',
			name: 'Identifying country',
		},
		{
			key: 'remarks',
			name: 'Remarks (Do not enter personal data)',
		},
		{
			key: 'divisionalfocus',
			name: 'Divisions',
		},
	];
	const handleSelectFileFormat = (event) => {
		setFileFormat(event.target.value);
	};

	// useEffect(() => {
	// 	geResellerByCountryService(selectedCountry)
	// 		.then((data) => {
	// 			setTableData(data);
	// 		})
	// 		.catch(() => {
	// 			setErrorMessage('Something went wrong, Please try again!');
	// 		});
	// }, [selectedCountry]);

	useEffect(() => {
		getAllResellerService()
			.then((data) => {
				setTableData(data);
			})
			.catch(() => {
				setErrorMessage('Something went wrong, Please try again!');
			});
	}, []);
	const handleStartExport = async () => {
		if (fileFormat === 'XLS') {
			try {
				const response = await axios.get(
					`${baseURL}${reseller.xsl.replace(':Country', selectedCountry)}`,
					{
						responseType: 'blob',
					}
				);
				if (response.status === 200) {
					const blob = new Blob([response.data], {
						type: 'application/vnd.ms-excel',
					});
					saveAs(blob, 'Reseller.xls');
				} else {
					console.error('failed to download');
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		} else if (fileFormat === 'CSV') {
			try {
				const response = await axios.get(
					`${baseURL}${reseller.csv.replace(':Country', selectedCountry)}`,
					{
						responseType: 'blob',
					}
				);
				if (response.status === 200) {
					const blob = new Blob([response.data], {
						type: 'text/csv',
					});
					saveAs(blob, 'Reseller.csv');
				} else {
					console.error('failed to download');
				}
			} catch (err) {
				console.error(err);
				throw new Error(err);
			}
		} else {
			console.log('please select correct file format');
		}
	};
	return (
		<div className="VerificationOfResellerWrapper">
			<Typography
				noWrap
				sx={{
					color: '#151C2D',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 600,
					fontFamily: 'Arial, Helvetica, sans-serif',
				}}
			>
				Verification of Reseller
			</Typography>
			<hr />
			<Grid container>
				<Grid item className="countryFileFormatWrapper">
					<Grid item width="25%">
						<AutoCompleteCountryDropdown
							width="90%"
							height="2.5em"
							placeholder="Identifying Country"
							value={selectedCountry}
							onChange={(newValue) => setSelectedCountry(newValue)}
						/>
					</Grid>
					<Grid item width="15%">
						<CustomDropDown
							options={options}
							multiple={false}
							label="Select file format"
							id="role-dropdown"
							onChange={handleSelectFileFormat}
							width={'7em'}
							selectedValue={fileFormat}
							defaultValue={'XLS'}
						/>
					</Grid>
					<Grid>
						<CustomButton
							text="Start export"
							width="8em"
							handleClick={handleStartExport}
						/>
					</Grid>
				</Grid>
				<br />

				<Grid item>
					<CustomTable
						page="Verify"
						tableData={tableData}
						errorMessage={errorMessage}
						colheaders={colheaders}
					/>
				</Grid>
				<p className="error-text">
					Please be aware that the information is confidential and for internal
					use only!
				</p>
			</Grid>
			<br />
			<br />
		</div>
	);
};
export default VerificationOfReseller;
