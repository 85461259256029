import axios from 'axios';
import { baseURL, userService } from '../Constants/APIUrls';

export const getUserInfo = async () => {
	try {
		const token = localStorage.getItem('AccessToken');
		const axiosInstance = axios.create({
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		const response = await axiosInstance.get(
			`${baseURL}${userService.userInfo}`
		);
		return response.data;
	} catch (err) {
		console.warn(err);
		throw new Error(err);
	}
};
