import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomPagination from './CustomPagination';

const CustomTable = (props) => {
	const colheaders = props?.colheaders;

	const width = props?.width ? `${props?.width}rem` : 'auto';
	return (
		<Grid>
			<Grid item sx={{ width: width }}>
				<TableContainer
					component={Paper}
					sx={{
						overflow: 'auto',
						'&::-webkit-scrollbar': { width: '12px', height: '12px' },
						'&::-webkit-scrollbar-track': {
							background: '#f1f1f1',
							margin: '40px',
							boxShadow: 'inset 7px 10px 12px #f0f0f0',
						},
						'&::-webkit-scrollbar-thumb': {
							background: '#ddd',
							borderRadius: '1rem',
						},
						'&::-webkit-scrollbar-thumb:hover': { background: '#888' },
					}}
				>
					<Table>
						<TableHead sx={{ backgroundColor: '#dddddd54' }}>
							<TableRow>
								{colheaders?.map((header) => (
									<TableCell
										align="center"
										key={header.key}
										sx={{
											fontWeight: 'bold',
											borderRight: '1px solid #ccc',
										}}
									>
										{header.name}
									</TableCell>
								))}
								{props?.page && (
									<TableCell
										align="center"
										sx={{
											fontWeight: 'bold',
											borderRight: '1px solid #ccc',
										}}
									>
										Action
									</TableCell>
								)}
							</TableRow>
						</TableHead>

						<TableBody>
							{props?.tableData?.length === 0 ||
							props?.errorMessage?.length !== 0 ? (
								<TableRow>
									<TableCell></TableCell>
								</TableRow>
							) : (
								props?.tableData?.map((data, index) => (
									<TableRow key={index}>
										{colheaders.map((header) => (
											<TableCell
												align="center"
												sx={{
													borderRight: '1px solid #ccc',
												}}
											>
												{header.key === 'divisionalfocus'
													? data[header.key]?.replace('||', ',')
													: data[header.key]}
											</TableCell>
										))}
										{props?.page && (
											<TableCell align="center">
												<Link to={`/${props?.page}/${data?.resellerid}`}>
													<button>{'Edit'}</button>
												</Link>
											</TableCell>
										)}
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>

			<br />

			{/* <Grid className="pagination">
				{props?.tableData?.length !== 0 && (
					<CustomPagination
						total={props?.tableData.length}
						data={props?.tableData}
					/>
				)}
			</Grid> */}
		</Grid>
	);
};

export default CustomTable;
