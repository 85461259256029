import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../Store/languageSlice';
import authReducer from '../Store/authSlice';
import roleSliceReducer from '../Store/roleSlice';

export default configureStore({
	reducer: {
		languages: languageReducer,
		auth: authReducer,
		role: roleSliceReducer,
	},
});
